import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "configuration"
    }}>{`Configuration`}</h1>
    <p>{`Some of the tracking code options are optional and can be set using `}<a parentName="p" {...{
        "href": "/javascript-api/methods/#setoptions"
      }}><inlineCode parentName="a">{`setOptions`}</inlineCode></a>{` or `}<a parentName="p" {...{
        "href": "/javascript-api/methods/#init"
      }}><inlineCode parentName="a">{`init`}</inlineCode></a>{` using methods.`}</p>
    <h3 {...{
      "id": "options"
    }}>{`Options`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`accountID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your `}<inlineCode parentName="td">{`accountID`}</inlineCode>{`. Automatically extracted from `}<inlineCode parentName="td">{`trackingID`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`websiteID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your `}<inlineCode parentName="td">{`websiteID`}</inlineCode>{`. Automatically extracted from `}<inlineCode parentName="td">{`trackingID`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`keystrokes`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enable global keystroke tracking. `}<a parentName="td" {...{
              "href": "https://livesession.io/help/how-to-record-keystrokes/"
            }}>{`Read more`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rootHostname`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`null`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set this to the highest-level hostname to record session across different subdomains on your site.  (e.g. `}<inlineCode parentName="td">{`.your-domain.com`}</inlineCode>{`)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "examples"
    }}>{`Examples`}</h3>
    <p>{`Using `}<a parentName="p" {...{
        "href": "/javascript-api/methods/#init"
      }}><inlineCode parentName="a">{`init`}</inlineCode></a></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("init", "abc.cdef", { keystrokes: true });
`}</code></pre>
    <p>{`Using `}<a parentName="p" {...{
        "href": "/javascript-api/methods/#setoptions"
      }}><inlineCode parentName="a">{`setOptions`}</inlineCode></a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`__ls("setOptions", { keystrokes: true });
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      